.box-wrap {
  filter: drop-shadow(-1px 2px 4px rgba(0, 0, 0, 0.5));

  .box-info {
    text-align: center;
    border-radius: 4px;
    min-height: 500px;
    clip-path: polygon(0 0, 100% 0, 100% 48%, 97% 50%, 100% 52%, 100% 100%, 0 100%, 0 52%, 3% 50%, 0 48%);
    background: repeating-linear-gradient(to right, rgba(39, 79, 152, 0.12) 0px, rgba(39, 79, 152, 0.12) 5px, transparent 5px, transparent 10px) center/100% 2px no-repeat;


    .hr-cut {
      position: relative;
      width: 95%;
      margin: 0 auto;
      border: none;
      border-top: 2px dashed rgba(39, 79, 152, 0.12);
    }

    .info {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 40px 20px 8px;

      h1, h2, h6, label {
        margin: 0;
      }

      h1 {
        span {
          font-size: 12px;
          line-height: 20px;
          font-weight: bold;
          margin-bottom: 10px;
          color: #000;
        }
      }

      h6 {
        font-weight: bold;
        font-size: 11px;
        line-height: 20px;
      }

      label {
        display: block;
        margin: 0;
        line-height: 20px;
        color: rgba(5, 22, 52, 0.87);
        font-size: 10px;
        font-weight: 300;

        &.document {
          font-weight: normal;
          font-size: 11px;
          line-height: 20px;
          color: #000000;
        }

        &.accepted-brands {
          margin-top: 14px;
          font-weight: normal;
          font-size: 10px;
          line-height: 20px;
          color: rgba(5, 22, 52, 0.87);
        }

        &.description {
          margin-top: 12px;
          font-size: 9px;
        }
      }

      &.info-client {
        position: relative;
        padding: 0 40px;
        min-height: 250px;

        .referenceId {
          width: 100%;
          left: 0;
          position: absolute;
          top: 5px;
          display: flex;
          align-items: center;
          justify-content: center;

          label {
            font-size: 12px;
          }
        }

        .info-data {
          margin-top: 50px;
        }
      }

      &.info-payment {
        padding: 0 40px;
        min-height: 250px;
      }
    }

    .brands {
      .brand {
        margin: 2px;
        display: inline-block;
        align-items: center;
      }
    }
  }

}


