.loading-full {
  background-color: #f4f4f4;
  display: flex;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;

  .modal {
    width: 320px;
  }
}

.loading {
  width: auto;
  margin-left: 10px;
  margin-top: 6px;

  .modal {
    width: auto;
  }
}
