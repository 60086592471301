.card-tile {
  display: flex;
  align-items: center;
  justify-items: start;
  justify-content: space-between;
  padding: 20px 30px;
  cursor: pointer;
  background: white;
  /* Old browsers */
  background: linear-gradient(to left, rgb(238, 238, 238) 50%, white 50%);
  background-size: 200% 100%;
  background-position:left bottom;
  transition:all 1s ease;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  &.selected {
    background-position:right bottom;
  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  }

  .description {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    h3, h5 {
      margin: 0;
    }

    h5 {
      font-weight: 200;
    }
  }

  .checkbox {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid rgba(128, 128, 128, 0.44);
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;

    .checkbox-content {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
}
