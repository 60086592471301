.qrcode-pix {
  .title {
    display: flex;
    align-items: center;
  }

  .content {
    text-align: center;
    background: #FFFFFF;
    border-radius: 2px;
    padding: 40px 20px;

    fieldset {
      box-sizing: border-box;
      border-radius: 10px;

      .copy-clipboard{
        width: 90%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        .bt-copy {
          width: 60px;
          height: 60px;
        }

        p {
          white-space: nowrap;
          width: 160px;
          overflow: hidden; /* "overflow" value must be different from "visible" */
          text-overflow: ellipsis;
        }
      }

      legend {
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        svg {
          margin-right: 6px;
        }
      }

      &.copied {
        border: 1px solid #00CC96;
      }
    }

    .copied {
      color: #00CC96;
      svg {
        color: #00CC96;
      }
    }

    .svg-qrcode {
      svg {
        min-width: 180px;
        width: 50%;
        height: auto !important;
      }
    }
  }
}
