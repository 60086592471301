@media(max-width: 600px) {
  .intetion-content-custom {
    h1 {
      font-size: 1.8rem !important;
    }
  }
}
.principal-description h1 {
  font-size: 32px;
  line-height: 40px;
}