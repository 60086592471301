.authentication {
  background-color: #f4f4f4;
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;

  .modal {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    width: 320px;
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
  }
}
