.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(36, 36, 36, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  .modal-custom {
    width: 60%;
    margin: 0 auto;
    min-width: 250px;
    max-height: 90vh;
    background: #FFFFFF;
    position: relative;

    .close {
      position: absolute;
      right: 10px;
      top: 10px;
    }

    .header {
      background: #F0F0F0;
      padding: 40px;

      .title {
        font-family: Montserrat, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 3px;
        margin-top: 0;
      }

      .description {
        font-family: Montserrat, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #5C5C5C;
        margin-top: 1px;
        text-indent: 2px;
      }
    }

    .content {
      padding: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

}

@media (max-width: 1440px) {
  .modal-backdrop {
    .overflow {
      .modal-custom {
        width: 80%;
      }
    }
  }
}

@media (max-width: 600px) {
  .modal-backdrop {
    .overflow {
      .modal-custom {
        width: 90%;

        .header, .content {
          padding: 40px 20px;
        }
      }
    }
  }
}


