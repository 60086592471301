.preview-slip {
  width: 100%;
  //box-shadow: -1px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 4px;
  text-align: center;

  .barcode {
    svg {
      max-width: 100%;
    }
  }
}
