.update-brand {
  min-width: 310px;
  width: 40vw;
  padding: 20px;
  text-align: center;

  .brands {
    .brand {
      display: inline-block;

      .icon-card {
        margin: 5px;
        cursor: pointer;
        max-height: 55px !important;
      }
    }
  }
}

.preview-card-icon {
  .icon-card {
    max-height: available;
  }
}

.button {
  padding: 8px 16px;
  width: 328px;
  height: 48px;
  filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.08));
  background: linear-gradient(105.23deg, #FFE552 0%, #FFCE2E 100%);
  border-radius: 64px !important;
}
