.brcode-pix {
  width: 100%;

  .qrcode {
    svg {
      width: 100%;
      height: auto;
      background: transparent;
    }
  }

  .bt-ctrl {
    width: 180px;
  }
}
