.payment-method {
  margin-top: 40px;
  width: 100%;

  .pix-icon {
    width: 24px;
  }

  .pix-info-title {
    display: flex;
    align-items: center;
    justify-content: left;
  }
}
