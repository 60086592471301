.intention-customize {
  .fab-button-customize{
    position: fixed;
    bottom: 20px;
    right: 10px;
  }

  .drawer-customize {
    padding: 10px;
  }

  .drawer-close {
    text-align: center;
  }

  .hidden-upload {
    display: none;
  }
}

.principal-description h1 {
  line-height: 120%;
}
