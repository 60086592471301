.info-pix {
  .steps {
    display: flex;
    overflow-y: auto;
    max-height: 40vh;

    .step {
      width: 33.3333%;
      margin: 5px 10px;
      position: relative;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      h4 {
        font-family: Montserrat, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 13px;
      }

      p, li {
        margin: 0;
        font-family: Montserrat, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
      }

      ul {
        display: none;
      }
    }
  }

  .row-end {
    text-align: center;

    .actions {
      width: 100%;

      hr {
        margin-top: 40px;
        border: 1px solid rgba(36, 36, 36, 0.1);
      }

      .bt-radius {
        margin-top: 60px;
        border-radius: 24px;
        padding: 16px 24px;
        width: 100%;
        max-width: 330px;
        font-family: Montserrat, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.75px;
      }
    }
  }
}


@media (max-width: 880px) {
  .info-pix {
    .steps {
      display: block;
      .step{
        width: 100%;
        margin: 10px 0 !important;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(36, 36, 36, 0.1);

        ul {
          display: block;
        }
      }
    }
    .row-end {
      .actions {
        hr {
          display: none;
        }
        .bt-radius {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}
